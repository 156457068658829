var myApp = angular.module('myApp', ['ngRoute', 'ngCookies', 'ngDialog', 'angular-loading-bar', 'angularMoment' ,'moment-picker']);

// myApp.constant(‘isLoading’, false);
myApp.directive('toIso',function($filter) {
  return {
     
    require: 'ngModel',
    link: function(scope, element, attrs, ngModelController) {
      ngModelController.$parsers.push(function(data) {
                if (data!== undefined ) {
                data = $filter('date')(data, 'mediumDate');

            }

        console.log(data);
         
         
            

        // data=moment(data).toISOString();
        //     console.log( data);

              
            
        return data; //converted
      });

      ngModelController.$formatters.push(function(data) {
        //convert data from model format to view format
        return data; //converted
      });
    }
  }
});



myApp.filter('role', function() {
    return function(item) {
        if (item == '1') {

            return 'Admin';
        }
        if (item == 2) {

            return 'Employee';
        }
        if (item == 3) {

            return 'guest';
        }
        else
            return item;
    };
});

myApp.filter('myTime', function() {
    return function(item) {
        var dt = item.split(/\-|\s/);
        return new Date(dt.slice(0, 3).join('-') + ' ' + dt[3]);
    };
});

myApp.filter('toTime', function() {
    return function(item) {
        var dt = item.split(" ");
        return dt[1];
    };
});


myApp.filter('breakLine', function() {
    return function(item) {
        var dt = item.split("$");
        return dt;
    };
});




myApp.config(['$routeProvider', '$locationProvider',
    function($routeProvider, $locationProvider) {

        $routeProvider.when('/', {
            templateUrl: 'templates/users/login.html',
            controller: 'userController',
            authenticated: false,
            isAdmin: false
        });
        $routeProvider.when('/resetpassword', {
            templateUrl: 'templates/users/resetpassword.html',
            controller: 'userController',
            authenticated: false,
            isAdmin: false
        });
        $routeProvider.when('/dashboard', {
            templateUrl: 'templates/users/dashboard.html',
            controller: 'userController',
            authenticated: true,
            isAdmin: false
        });
        $routeProvider.when('/dashboard/employees', {
            templateUrl: 'templates/employees/employees-view.html',
            controller: 'employeesController',
            authenticated: true,
            isAdmin: true
        });
        $routeProvider.when('/dashboard/invoice', {
            templateUrl: 'templates/invoice/invoice-view.html',
            controller: 'invoiceController',
            authenticated: true,
            isAdmin: false

        });
        $routeProvider.when('/dashboard/fileno', {
            templateUrl: 'templates/fileno/fileno-view.html',
            controller: 'filenoController',
            authenticated: true,
            isAdmin: false
        });
        $routeProvider.when('/dashboard/coversheet', {
            templateUrl: 'templates/coversheet/coversheet-view.html',
            controller: 'coversheetController',
            authenticated: true,
            isAdmin: false
        });
        $routeProvider.when('/logout', {
            templateUrl: 'templates/users/logout.html',
            controller: 'userController',
            authenticated: true,
            isAdmin: false
        });
        $routeProvider.otherwise('/');



    }





]);

myApp.run(['$rootScope', '$location', 'userModel',
    function($rootScope, $location, userModel) {
        $rootScope.$on("$routeChangeStart",
            function(event, next, current) {





                if (next.authenticated) {

                    if (!userModel.getAuthStatus()) {
                        $location.path('/');
                    }
                    if (next.isAdmin) {
                        if (!userModel.isAdmin()) {
                            $location.path('/');
                        }


                    }
                }
                if (next.originalPath == '/') {
                    if (userModel.getAuthStatus()) {


                        if (current.originalPath) {
                            $location.path(current.originalPath);
                        }
                        else {
                            $location.path('/dashboard');
                        }
                    }
                }

            })


    }
]);


myApp.directive('pagination', function() {
    return {
        restrict: 'E',
        template: '<ul class="pagination"  ng-if="lastPage !== 0 ">' +
            '<li ng-show="currentPage != 1"><a href="javascript:void(0)" ng-click="getDatas(1)">First</a></li>' +
            '<li ng-show="currentPage != 1"><a href="javascript:void(0)" ng-click="getDatas(currentPage-1)">&lsaquo; Prev</a></li>' +
            '<li ng-repeat="i in range" ng-class="{active : currentPage == i}" >' +
            '<a href="javascript:void(0)" ng-click="(currentPage !== i) && getDatas(i)">{{i}} </a>' +
            '</li>' +
            '<li ng-show="currentPage != lastPage "><a href="javascript:void(0)" ng-click="getDatas(currentPage+1)">Next &rsaquo;</a></li>' +
            '<li ng-show="currentPage != lastPage "><a href="javascript:void(0)" ng-click="getDatas(lastPage)">Last</a></li>' +
            '</ul>'
    };
});
